
.PageArticle article {
	margin-bottom: 20px;
}

.PageArticle article .PageArticle-content-cover {
	position: relative;
	margin: auto;
	background: repeating-linear-gradient(
      135deg,
      #f8f9fa,
      #f8f9fa 10px,
      rgba(0, 0, 0, 0) 10px,
      rgba(0, 0, 0, 0) 20px
    );
	text-align: center;
	min-height: 50px;
}

.PageArticle article .PageArticle-content-cover img {
	max-width: 100%;
	min-height: 50px;
	max-height: 300px;
}

.PageArticle article .content-media {
	margin: 15px auto;
	text-align: center;
	display: flex;
  	justify-content: center;
}

.PageArticle article .content-media img {
	max-width: 100%;
	max-height: 300px;
}

.PageArticle article .content-media iframe {
	max-width: 100%;
}

.PageArticle article .content-media p {
	margin: 0 !important;
}

.PageArticle-related-article {
	background-color: #f8f9fa;
}

.PageArticle-social-media {
	background-color: #f8f9fa;
	margin-bottom: 15px;
}

.PageArticle-social-media-links {
	width: 100%;
	text-align: center;
}

.PageArticle-social-media-links .TwitterLink, .PageArticle-social-media-links .LinkedInLink {
	display: inline-block;
}

.PageArticle-abstract {
	font-size: 18px;
	margin: 15px 0px;
}

.PageArticle-publication-date {
	position: absolute;
	right: 30px;
	bottom: 0px;
	background: #109E92;
    text-align: center;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
}

.PageArticle-tags {
	padding: 30px 10px 5px 10px;
}

.PageArticle-companies {
	padding: 5px 10px 5px 10px;
}

/* Collapsible */ 

.PageArticle .Collapsible {
	margin: 30px 0px 0px 0px !important;
	border-top: solid 0px !important;
    border-bottom: solid 0px !important;
}

.PageArticle .Collapsible__trigger p {
	position: relative;
	padding: 8px 70px 8px 20px;
	font-style: italic;
	cursor: pointer;
	margin-bottom: 0px;
}

.PageArticle .Collapsible .is-closed:after {
    top: 45px !important;
    right: 10px;
}

.PageArticle .Collapsible .is-open:after {
    top: 45px !important;
    right: 10px;
}