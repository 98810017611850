
.Footer {
  width: 100%;
  background-color: black;
  color: white;
  font-size: 12px;
  line-height: 1.2;
  z-index: 150;
  padding: 50px 0px 30px 0px;
}

.Footer .Footer-patronage {
  color: white;
  margin-bottom: 15px;
  font-size: 14px;
}

.Footer-content .row > div {
  margin-bottom: 25px;
}

.Footer a {
  color: white;
}

.Footer a:hover {
  color: red;
}

.Footer .Footer-title {
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
}

.Footer-content {
  margin: 0px auto 0px auto;
  padding: 25px 40px 15px 40px;
  max-width: 1240px;
}

.Footer-network {
  font-size: 20px;
  padding: 5px 8px;
  text-align: center;
  color: #109E92;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .Footer-network {
    margin-bottom: 60px !important;
  }
}


.Footer-network a i:hover {
  color: #109E92 !important;
}

.Footer-documentation-and-terms a {
  margin: 5px 8px;
  display: block;
}

.Footer-alinea {
  padding-left: 15px;
}