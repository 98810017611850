
.ServiceHorizontal {
    margin: 5px 0px !important;
    overflow: hidden;
    line-height: 1 !important;
}

.ServiceHorizontal-link {
    color: inherit;
}

.ServiceHorizontal-link:hover {
    color: initial !important;
    text-decoration: none;
}

.ServiceHorizontal:hover {
    background-color: #e7f5f4;
}

@media (min-width: 500px) {
    .ServiceHorizontal .card-horizontal {
        height: 100%;
        display: flex;
        flex: 1 1 auto;
    }
}

@media (min-width: 0px) {
    .ServiceHorizontal .img-square-wrapper {
        width: 100%;
        height: 130px;
    }
}

@media (min-width: 500px) {
    .ServiceHorizontal .img-square-wrapper {
        object-fit: cover;
        display: flex;
        margin: auto;
        flex: 0 0 220px;
        padding: 12px 15px !important;
    }
}

.ServiceHorizontal .img-square-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: unset !important;
}

.ServiceHorizontal .card-date {
    position: absolute;
    background: #109E92;
    text-align: center;
    padding: 10px;
    color: #fff;
    bottom: 0px;
    left: 15px;
    text-transform: uppercase;
}

.ServiceHorizontal .card-body {
    padding: .9rem 1rem !important;
}

@media (min-width: 0px) {
    .ServiceHorizontal .card-body button {
        display: none;
    }
}

@media (min-width: 500px) {
    .ServiceHorizontal .card-body button {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 15px;
    }
}

.ServiceHorizontal .card-body h5 {
    font-weight: bold !important;
    margin-bottom: 5px !important;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 0px) {
    .ServiceHorizontal .card-body button {
        display: none;
    }
}

@media (min-width: 500px) {
    .ServiceHorizontal .card-body button {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 15px;
    }
}

.ServiceHorizontal .card-tags {
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ServiceHorizontal .card-text {
    padding: 5px 1rem 55px 1rem;
    text-align: justify;
}